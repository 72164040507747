import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class PubSubService {
    private _eventBus: Subject<PubSubMessage> = new Subject<PubSubMessage>();

    // Public property for components to subscribe to messages
    public messageStream: Observable<PubSubMessage> = this._eventBus.asObservable();

    constructor() { }

    // Method for component to publish message
    publishMessage(pubSubMessage: PubSubMessage) {
        this._eventBus.next(pubSubMessage);
    }
}

export class PubSubMessage {
    MessageType: string;
    MessageData: any;
}

export class PubSubMessageTypes {
    public static get ACTIVITY_SAVED(): string { return 'ACTIVITY_SAVED'; }
    public static get COLLATERAL_SAVED(): string { return 'COLLATERAL_SAVED'; }
    public static get CLIENT_SAVED(): string { return 'CLIENT_SAVED'; }
    public static get CLIENT_EMAIL_CHANGED(): string { return 'CLIENT_EMAIL_CHANGED' }
    public static get GROUP_SAVED(): string { return 'GROUP_SAVED'; }
    public static get LOGIN_REFRESHED(): string { return 'LOGIN_REFRESHED'; }
    public static get OPPORTUNITY_MOVED_TO_DEAL(): string { return 'OPPORTUNITY_MOVED_TO_DEAL'; }
    public static get OPPORTUNITY_SAVED(): string { return 'OPPORTUNITY_SAVED'; }
    public static get PRODUCT_SAVED(): string { return 'PRODUCT_SAVED'; }
    public static get TRACKING_ITEM_SAVED(): string { return 'TRACKING_ITEM_SAVED'; }
    public static get WORKSPACE_SAVED(): string { return 'WORKSPACE_SAVED'; }
    public static get OPPORTUNITY_DEBT_SCHEDULE_CHANGE(): string { return 'OPPORTUNITY_DEBT_SCHEDULE_CHANGE'; }
    public static get THIRD_PARTY_INTEGRATIONS_SAVED(): string { return 'THIRD_PARTY_INTEGRATIONS_SAVED'; }
    public static get OPPORTUNITY_ACCEPT_RECOMMENDED_TERMS(): string { return 'OPPORTUNITY_ACCEPT_RECOMMENDED_TERMS' }
    public static get OPPORTUNITY_RECALCULATE_PAYMENT_DATES(): string { return 'OPPORTUNITY_RECALCULATE_PAYMENT_DATES' }
    public static get OPPORTUNITY_SBA_TAB_CHANGE(): string { return 'OPPORTUNITY_SBA_TAB_CHANGE' }
    public static get OPPORTUNITY_HAS_TERM_OUT_CHANGE(): string { return 'OPPORTUNITY_HAS_TERM_OUT_CHANGE'; }
    public static get DOCUMENT_SESSION_RECIPIENTS_CHANGED(): string { return 'DOCUMENT_SESSION_RECIPIENTS_CHANGED'; }
    public static get DOCUMENT_SESSION_CHANGED(): string { return 'DOCUMENT_SESSION_CHANGED'; }
    public static get DOCUMENT_SESSION_VALIDATE_RENDER_DOCUMENT_STATUS(): string { return 'DOCUMENT_SESSION_RENDER_DOCUMENT_STATUS'; }
}
